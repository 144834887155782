<template>
  <div class="small-file" :class="[store.getTheme()]">
    <router-link
      class="white-text"
      :to="{ name: 'Dossier', params: { search: file.searchQuery, title: file.title, detail: file.detail }}">
      <!-- <img class="smallImage" :src="`${shared.getImagesUrl()}/${file.illustrationArticleId}?size=small`" alt=""> -->
      <progressive-img customClass="smallImage"
        :src="`${shared.getImagesUrl()}/${file.illustrationArticleId}?size=small`"
        :placeholder="`${shared.getImagesUrl()}/${file.illustrationArticleId}?size=thumbnail`"
        fallback="/img/MahanaTahitiNEWS.svg"
      />
      <span class="title">{{file.title}}</span>
      <p class="info"><br>{{file.category}} <br> {{file.detail}}</p>
      <!-- <span class="info">{{file.category}}</span><br><span class="info">{{file.detail}}</span></p> -->
    </router-link>
  </div>
</template>

<script>
// import formatDistance from 'date-fns/formatDistance'
// import fr from 'date-fns/locale/fr'
import shared from '../shared'
import { store } from '../store'

export default {
  name: 'SmallFile',
  props: {
    file: {
      type: Object,
      required: true
    },
    isVisible: Boolean
  },
  data () {
    return {
      shared: shared,
      store: store
      // navigatorShare: navigator.share,
      // displayDate: formatDistance(new Date(this.file.date), new Date(), { addSuffix: true, locale: fr }),
      // interval: null
    }
  },
  computed: {
    // slug () {
    //   // console.log(shared.stringToSlug(this.file.title))
    //   return shared.stringToSlug(this.file.title)
    // },
    // shareLink () {
    //   return `${shared.getHostUrl()}/share/file/${this.file.id}/${this.slug}`
    // },
    // shareShortLink () {
    //   return `${shared.getHostUrl()}/share/file/${this.file.id}/1`
    // }
  },
  methods: {
    // updateDisplayDate () {
    //   this.displayDate = formatDistance(new Date(this.file.date), new Date(), { addSuffix: true, locale: fr })
    // },
    // webshare (title, text, shareLink) {
    //   if (navigator.share) {
    //     navigator.share({
    //       title: title,
    //       text: text,
    //       url: shareLink
    //     })
    //   }
    // }
  },

  mounted () {
    // eslint-disable-next-line no-undef
    M.Dropdown.init(this.$refs.dropdownTrigger, { })
    // this.interval = setInterval(() => {
    //   this.updateDisplayDate()
    // }, 60000)
  }
  // beforeDestroy () {
  //   clearInterval(this.interval)
  // }
}

</script>
<style scoped>
/* p {
  margin: 0
} */
.title {
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  position: absolute;
  text-align: left;
  left : 150px
}
.info {
  position: absolute;
  text-align: left;
  left : 150px;
  font-size: 12px;
  color: gray;
  font-style: italic;
}
/* a {
  cursor: pointer;
} */
.smallImage {
  height: 100px;
  width: 125px!important;
  position: absolute;
  left: 15px;
  /* object-fit: cover;
    -o-object-fit: cover; */
  /*margin-right: 15px;*/
  /* float:left */
}
.small-file {
  height: 110px
}
</style>
